import React from 'react';

import styles from '../../styles/AddedBefore.module.scss';
import Ad from '../Ad/Ad';
import LinkToMaterial from '../LinkToMaterial/LinkToMaterial';

const AddedBefore = ({
  direction,
  articles,
  banersData = {},
  withLabel = true,
}) => {
  const { baners, destination_urls } = banersData;
  return (
    <div
      className={`${styles.mainContainer} ${
        direction === 'column'
          ? styles.mainContainerCol
          : styles.mainContainerRow
      }`}
    >
      <p className={styles.header}>Wcześniej dodane</p>
      <div className={direction === 'column' ? styles.column : styles.row}>
        {articles?.map((article) => (
          <LinkToMaterial
            material={article}
            variant={'small'}
            withLabel={withLabel}
            key={'article-' + article.id}
          />
        ))}
        {Object.keys(banersData).length > 0 && (
          <Ad
            baner={baners[1]}
            url={destination_urls[1]}
            type={'long'}
            key={baners[1]}
          />
        )}
      </div>
    </div>
  );
};

export default AddedBefore;
