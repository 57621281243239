import NextLink from 'next/link';
import Link from 'next/link';
import React from 'react';

import styles from '../../styles/Ad.module.scss';

const Cooperate = () => {
  return (
    <div className={styles.cooperate}>
      <h3 className={styles.cooperateHeader}>Współpraca z nami</h3>
      <div className={styles.wrapperModule}>
        <Link href={{ pathname: '/reklama' }} className={styles.cardLink}>
          <div
            className={styles.cardModule}
            style={{
              background: `linear-gradient(180deg, rgba(36, 42, 53, 0) 6.25%, #242a35 75%), url('/reklama.png')`,
            }}
          >
            <p>Reklama</p>
          </div>
        </Link>
        <Link href={{ pathname: '/wynajem-studia' }}>
          <div
            className={styles.cardModule}
            style={{
              background: `linear-gradient(180deg, rgba(36, 42, 53, 0) 6.25%, #242a35 75%), url('/wynajem_studia.jpg')`,
            }}
          >
            <p>
              Wynajem <br />
              studia HD TV
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Cooperate;
