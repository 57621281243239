import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';

import { calculateWidth } from '../../utils/calculateWidth';
import Carousel from '../Carousel/Carousel';
import WatchNowCard from './WatchNowCard';
import { useMobile } from '../../utils/useMobile';
const WatchNow = ({ data }) => {
  const percent = data && calculateWidth(data, 'watchNow');
  const router = useRouter();
  const [programs, setPrograms] = useState(data);
  const { isMobile, isTablet } = useMobile();

  useEffect(() => {
    setPrograms(data);
  }, [isMobile, isTablet]);

  return (
    <>
      <div style={{ width: '100vw' }}>
        <Carousel
          key={router.asPath}
          theme={'dark'}
          arrows={true}
          center={true}
          percent={percent}
          infiniteLoop={true}
          header={'Obejrzyj teraz'}
          showIndicators={true}
          autoPlay={true}
          selectedItem={0}
          carouselVariant='watchNow'
        >
          {programs.map((video, idx) => (
            <WatchNowCard video={video} key={video + idx} />
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default WatchNow;
