import { Chip } from '@mui/material';
import Card from '@mui/material/Card';
import Image from 'next/image';
import { useRouter } from 'next/router';

import styles from '../../styles/LinkToMaterial.module.scss';

const LinkToMaterial = ({
  material: {
    image,
    category,
    title,
    sub_title,
    added_at,
    date,
    content,
    id,
    image_status,
    pretty_title_url,
  },
  material,
  variant,
  categories = [],
  withLabel = true,
}) => {
  const router = useRouter();
  const redirectToPage = () => {
    image_status !== undefined
      ? router.push(`/artykul/${id}`)
      : material.standalone === 'y'
      ? router.push({
          pathname: `/obejrzyj/${pretty_title_url}`,
        })
      : router.push({
          pathname: `/program/${pretty_title_url}`,
          query: { vid: id },
        });
  };
  return (
    <Card
      sx={{ margin: variant === 'wide' && 'auto' }}
      onClick={redirectToPage}
      className={`${styles.card} ${styles[variant]}`}
    >
      <div
        className={`${styles.cardWrapper} ${
          variant === 'wide'
            ? styles.wide
            : variant === 'small'
            ? styles.small
            : styles.big
        }`}
      >
        {variant === 'big' && (
          <>
            <div className={styles.topContainer}>
              {withLabel && (
                <Chip
                  label={
                    categories[category]
                      ? categories[category]
                      : category === 'regular'
                      ? 'Wydarzenia'
                      : category === 'reportaz'
                      ? 'reportaż'
                      : category
                  }
                  className={styles.label}
                />
              )}
              <p
                className={styles.date}
                style={{ marginLeft: withLabel ? '0' : '16px' }}
              >
                {added_at ?? date}
              </p>
            </div>
            <p
              className={`${styles.title} ${styles.bigTitle} ${styles.bigCard}`}
            >
              {title}
            </p>
          </>
        )}
        {variant !== 'big' && image && (
          <div className={styles.imageWrapper}>
            <Image
              src={image}
              className={styles.smallImage}
              layout='fill'
              objectFit='cover'
            />
          </div>
        )}
        {variant !== 'big' && !image && (
          <div className={styles.imageWrapper}>
            <Image
              src={'/toyaCover.png'}
              className={styles.smallImage}
              layout='fill'
              objectFit='cover'
            />
          </div>
        )}

        {variant !== 'big' && variant !== 'wide' && (
          <div className={styles.topContainer}>
            {withLabel && (
              <Chip
                label={
                  categories[category]
                    ? categories[category]
                    : category === 'regular'
                    ? 'Wydarzenia'
                    : category === 'reportaz'
                    ? 'reportaż'
                    : category
                }
                className={styles.label}
              />
            )}
            <p
              className={`${styles.date} ${styles.dateSmall}`}
              style={{ marginLeft: withLabel ? '0' : '16px' }}
            >
              {added_at ?? date}
            </p>
          </div>
        )}

        {variant === 'big' ||
          (variant === 'small' && (
            <p
              className={`${styles.title} ${
                variant === 'big' ? styles.bigTitle : styles.smallTitle
              }`}
            >
              {sub_title || title}
            </p>
          ))}

        {variant === 'big' && image && (
          <div className={styles.bigImageWrapper}>
            <img src={image} alt='image' />
          </div>
        )}
        {variant === 'big' && !image && (
          <div className={styles.bigImageWrapper}>
            <img src={'/toyaCover.png'} alt='image' />
          </div>
        )}
        {variant === 'big' && <p className={styles.content}>{content}</p>}
        {variant === 'wide' && (
          <div className={styles.wideLinkArchive}>
            <div>
              <Chip
                label={
                  categories[category]
                    ? categories[category]
                    : category === 'regular'
                    ? 'Wydarzenia'
                    : category
                }
                className={`${styles.label} ${styles.wideLabel}`}
              />
              <p className={`${styles.date} ${styles.wideDate}`}>
                {added_at ?? date}
              </p>
            </div>
            <div className={styles.flexContainer}>
              <div className={`${styles.title} ${styles.smallTitle}`}>
                {sub_title ? sub_title : title}
              </div>
              <button className={styles.showMore}>
                Zobacz więcej <img src='/darkBlueArrow.svg' />
              </button>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default LinkToMaterial;
