import { useRouter } from 'next/router';
import React from 'react';

import styles from '../../styles/WatchNowCard.module.scss';

const WatchNowCard = ({ video }) => {
  const router = useRouter();
  const goToPlayer = () => {
    video.pretty_title_url
      ? router.push({
          pathname: `/program/${video.pretty_title_url}`,
          query: { vid: video.id },
        })
      : router.push('/404');
  };

  return (
    <div className={styles.playerCard} onClick={goToPlayer}>
      <div
        className={styles.cardImage}
        style={{ backgroundImage: `url(${video.image})` }}
      />
      <div className={styles.insideContainer}>
        <p className={styles.title}>{video.sub_title}</p>
        <img src='/play.svg' alt='play icon' className={styles.playIcon} />
      </div>
    </div>
  );
};

export default WatchNowCard;
