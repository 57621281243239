import React from 'react';
import styles from '../../styles/Ad.module.scss';

const Ad = ({ baner, url }) => (
  <div
    className={styles.banerContainer}
    style={{
      display: !baner.length && 'none',
    }}
  >
    <p>Reklama</p>
    <a href={url} target='_blank'>
      <img src={baner} />
    </a>
  </div>
);

export default Ad;
